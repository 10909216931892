import React, { useContext, useState } from 'react';
import UserContext from '../../../contexts/userContext';
import moment from 'moment';

import { _empty } from '../../../utils/utils';
import { callApi } from '../../../api/API';

import './CustomerLogsTable.css';
import axios from 'axios';

const CustomerLogsTable = () => {
  const { userData } = useContext(UserContext);

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [customerLogs, setCustomerLogs] = useState([]);

  const [isRewardOpen, setIsRewardOpen] = useState(isRewardOpen);

  const getCustomerLogs = async (payload) => {
    try {
      const getLogs = await callApi(
        `/visits/customer-transaction-logs?${name && 'name=' + name}${type && '&reward=' + type}${
          transactionId && '&transactionId=' + transactionId
        }`,
        'GET',
        payload,
        'Bearer ' + userData?.token,
      );
      if (getLogs?.status === 404) {
        setCustomerLogs([]);
        return;
      }
      setCustomerLogs(getLogs?.history);
    } catch (err) {
      console.log('err', err);
    }
  };

  const downloadImage = async (url) => {
    // Create a link element
    try {
      const response = await fetch(url);

      if (response.ok) {
        const imageBlob = await response.blob();
        const imageUrl = window.URL.createObjectURL(imageBlob);

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'image.jpg'; // Specify the name and extension for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(imageUrl);
      }
    } catch (err) {
      console.log('api err', err);
    }
  };

  React.useEffect(() => {
    userData && getCustomerLogs();
  }, [userData, name, transactionId, type]);

  return (
    <React.Fragment>
      <div className='filter d-flex flex-wrap mt-2'>
        <div className='col-12 col-sm-6 col-md-4'>
          <label className='text-black filter-title'>Name</label>
          <input
            className='w-100 outline-none filter-text'
            placeholder='search by customer name'
            onChange={({ target: { value } }) => setName(value)}
          />
        </div>
        <div className='col-12 col-sm-6 col-md-4'>
          <label className='text-black  filter-title'>Transaction Id</label>
          <input
            className='w-100 outline-none filter-text'
            placeholder='search by transaction id'
            onChange={({ target: { value } }) => setTransactionId(value)}
          />
        </div>
        <div className='col-12 col-sm-6 col-md-4'>
          <label className='text-black filter-title'>Select Reward Type</label>
          <select
            className='w-100 outline-none filter-text'
            style={{ height: '24px' }}
            onChange={(e) => setType(e.target.value)}
          >
            <option value=''>All</option>
            <option value='earn'>Earn</option>
            <option value='redeem'>Redeem</option>
          </select>
        </div>
      </div>
      {customerLogs?.length ? (
        <div className='table-container table_wrapper flex-grow-1 position-relative'>
          <table className='business-table border-0 m-0' style={{ width: '100%' }}>
            <tbody>
              <tr className='table-row-container'>
                <th className='table-heading'>Customer Name</th>
                <th className='text-center table-heading'>Customer Email</th>
                <th className='text-center table-heading'>Amount</th>
                <th className='text-center table-heading'>Reward Title</th>
                {/* <th className='text-center table-heading'>Reward Status</th> */}
                <th className='text-center table-heading'>Earn Reward</th>
                <th className='text-center table-heading'>Earn Points</th>
                <th className='text-center table-heading'>Redeemed Reward</th>
                <th className='text-center table-heading'>Visit Number</th>
                <th className='text-center table-heading'>Payment Status</th>
                <th className='text-center table-heading'>Payment Id</th>
                <th className='text-center table-heading'>Transaction Id</th>
                <th className='text-center table-heading'>Timezone</th>
                <th className='table-heading'>TimeStamp</th>
                <th className='table-heading text-center'>Reciept Image</th>
              </tr>
              {customerLogs?.length
                ? customerLogs?.map((log) => (
                    <tr key={log?._id}>
                      <td className='align-middle text-black'>
                        {log?.userfName} {log?.userlName}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.userEmail || 'N/A'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.amount || 'N/A'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.rewardTitle || 'N/A'}
                      </td>
                      {/* <td className='text-center align-middle text-black'>
                    {log?.rewardStatus ? 'true' : 'false'}
                  </td> */}
                      <td className='text-center align-middle text-black'>
                        {log?.earnReward ? 'Earned' : 'Not Earned'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.earnPoints || 'N/A'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.redeemedReward ? 'Redeemed' : 'Not Redeemed'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.visitNumber || 'N/A'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.paymentStatus || 'N/A'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.paymentId || 'N/A'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.transactionId || 'N/A'}
                      </td>
                      <td className='text-center align-middle text-black'>
                        {log?.timezone || 'N/A'}
                      </td>
                      <td className='align-middle text-black timestamp'>
                        {log?.timeStamp
                          ? moment(log?.timeStamp * 1000).format('DD-MMM-YYYY, HH:MM')
                          : 'N/A'}
                      </td>
                      <td className='text-center text-black'>
                        <button
                          className='download-btn'
                          disabled={!log?.imageId}
                          onClick={async () => {
                            const response = await axios.get(
                              `${process.env.RAZZLE_RUNTIME_APP_BASEURL}/visits/customer-transaction-logs/image?image=${log?.imageId}`,
                            );
                            if (response?.status === 200) {
                              downloadImage(response?.data?.url);
                            }
                          }}
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='w-100 d-flex justify-content-center align-items-center h-100'>
          No Logs Found
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomerLogsTable;
