import React, { useContext } from 'react';

import { callApi } from '../../api/API';
import RedeemRewardForm from './RedeemForm/RedeemForm';
import RedeemConfirmationModal from './RedeemConfirmationModal/RedeemConfirmationModal';
import UserContext from '../../contexts/userContext';
import SuccessModal from './SucessModal/SuccessModal';

import './redeem.css';

function RedeemReward() {
  const { userData } = useContext(UserContext);

  const [confirmModal, setConfrimModal] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState(false);
  const [rewardData, setRewardData] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const redeemRequestCheck = async (payload) => {
    try {
      const getData = await callApi(
        '/visits/post-checkout/redeem-request-check',
        'POST',
        payload,
        'Bearer ' + userData?.token,
      );
      if (getData?.status === 404) {
        setRewardData(null);
      }
      if (getData?.status === 400) {
        setRewardData({ errorMessage: 'The code is either invalid or expired' });
      }
      if (getData?.message) {
        setRewardData(getData);
      }
      return getData;
    } catch (err) {}
  };

  const redeemReward = async (payload) => {
    try {
      const getData = await callApi(
        '/visits/post-checkout/redeem-request-complete',
        'POST',
        payload,
        'Bearer ' + userData?.token,
      );
      // if (getData?.status === 404) {
      //   setRewardData(null);
      // }
      // if (getData?.status === 400) {
      //   setRewardData({ errorMessage: 'The code is either invalid or expired' });
      // }
      // if (getData?.message) {
      //   setRewardData(getData);
      // }
      if (getData?.status === 400) {
        setMessage('Error redeeming code');
        setResponseModal(true);
      }
      if (getData?.message) {
        setMessage(getData?.message);
        setResponseModal(true);
        setConfrimModal(false);
      }
      return getData;
    } catch (err) {}
  };

  return (
    <div className='reward_container'>
      <header className='secttion-container__header reward_header'>
        <h2 className='title'>Redeem Reward</h2>
      </header>
      <div>
        {/* <input
          type='text'
          className=''
          placeholder='Enter Redeem code here'
          maxLength={6}
          minLength={6}
        /> */}
        <RedeemRewardForm
          redeemRequestCheck={redeemRequestCheck}
          setConfrimModal={setConfrimModal}
        />
      </div>
      {confirmModal && (
        <RedeemConfirmationModal
          open={confirmModal}
          setIsOpen={setConfrimModal}
          data={rewardData}
          redeemReward={redeemReward}
        />
      )}
      {responseModal && (
        <SuccessModal open={responseModal} setOpen={setResponseModal} message={message} />
      )}
    </div>
  );
}

export default RedeemReward;
