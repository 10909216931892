import React from 'react';
import { Button } from 'react-bootstrap';

import './modal.css';

function RedeemConfirmationModal({ open, setIsOpen, data, redeemReward }) {
  return (
    <div className='modal-container'>
      <dialog className='modal' open={open}>
        {!data ? (
          <>
            <div className='no-data'>No data Found!</div>
            <div className='footer'>
              <button
                className='cancel-button settings'
                type='button'
                onClick={() => setIsOpen(!open)}
              >
                Close
              </button>
            </div>
          </>
        ) : data?.errorMessage ? (
          <>
            <div className='no-data'>{data?.errorMessage}</div>
            <div className='footer'>
              <button
                className='cancel-button settings'
                type='button'
                onClick={() => setIsOpen(!open)}
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <>
            <div>
              <h2>Rewards Details</h2>
              <div className='content-field'>
                <h4>Customer Name</h4>
                <p>
                  {data?.user?.fname} {data?.user?.lname}
                </p>
              </div>
              <div className='content-field'>
                <h4>Title</h4>
                <p>{data?.reward?.title}</p>
              </div>
              <div className='content-field'>
                <h4>Description</h4>
                <p>{data?.reward?.description}</p>
              </div>
              <div className='content-field'>
                <h4>Discount Percentage</h4>
                <p>{data?.reward?.discountPercentage}%</p>
              </div>
            </div>
            <div className='footer'>
              <button className='cancel-button' type='button' onClick={() => setIsOpen(!open)}>
                Cancel
              </button>
              <Button
                style={{ width: '100%' }}
                variant='link'
                className='settings submit-button'
                type='button'
                onClick={async () => {
                  const res = await redeemReward({ redeemCode: data?.checkCode?.code });
                  console.log('redeem res', res);
                }}
              >
                Confirm
              </Button>
            </div>
          </>
        )}
      </dialog>
    </div>
  );
}

export default RedeemConfirmationModal;
