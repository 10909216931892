import React from 'react';
import '../RedeemConfirmationModal/modal.css';

function SuccessModal({ open, setOpen, message }) {
  return (
    <div className='modal-container'>
      <dialog className='modal' open={open}>
        <h2>{message?.includes('Error') ? 'Error' : 'Success'}</h2>
        <div className='' style={{ textAlign: 'center' }}>
          {message}
        </div>
        <div className='footer'>
          <button className='cancel-button settings' type='button' onClick={() => setOpen(!open)}>
            Close
          </button>
        </div>
      </dialog>
    </div>
  );
}

export default SuccessModal;
