// import './RewardForm.css';

import React from 'react';
import { Button, Form, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import './redeemform.css';

const RedeemRewardForm = ({ redeemRequestCheck, setConfrimModal }) => {
  const { register, handleSubmit, errors } = useForm();

  const [loading, setLoading] = React.useState(false);

  const handleSubmitForm = async (payload) => {
    setLoading(true);
    await redeemRequestCheck(payload);
    setLoading(false);
    setConfrimModal(true);
  };

  return loading ? (
    <div className='text-center'>
      <Loader type='Bars' color='#FF992E' />
      <p>Loading reward information...</p>
    </div>
  ) : (
    <Form autoComplete='off' className='form_wrapper' onSubmit={handleSubmit(handleSubmitForm)}>
      <Form.Group>
        <FormLabel style={{ fontSize: '14px', fontWeight: 600 }}>Redeem Code</FormLabel>
        <Form.Control
          style={{ fontSize: '16px' }}
          autoComplete='off'
          name='redeemCode'
          ref={register({ required: true })}
          maxLength={6}
          type='text'
          className={`input ${errors.redeemCode ? 'is-invalid' : ''} shadow-none`}
          placeholder='Emter redeem code'
        />
        {errors.redeemCode && <p style={{ color: 'red' }}>Redeem code is required</p>}
      </Form.Group>
      <Form.Group className='align-content-end'>
        <Button
          style={{ width: '100%' }}
          variant='link'
          className='text-decoration-none shadow-none settings mt-2'
          type='submit'
        >
          Redeem
        </Button>
      </Form.Group>
    </Form>
  );
};

export default RedeemRewardForm;
